.profitability {
  width: 100%;
  height: auto;
}
.profitability_media {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 701 / 712;
}
.profitability_content {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
  height: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}
.profitability_content__heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 76px;
}
.profitability_content__items-list:global(.ant-list .ant-list-items) {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.profitability_content__items-list:global(.ant-list .ant-list-items .ant-list-item) {
  justify-content: flex-start;
  border-block-end: none;
}
.profitability_content__items-list___item {
  display: flex;
  gap: var(--list-gap-normal);
  width: 100%;
  height: 90px;
}
.profitability_content__items-list___item-icon {
  width: 90px;
  min-width: 90px;
  height: 90px;
  padding: 12px;
  background: var(--corporate-orange);
}
.profitability_content__items-list___item-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
