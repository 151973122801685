.signup-wizard {
  height: inherit;
}
.signup-wizard__top {
  padding-top: 40px;
  padding-bottom: 40px;
}
.signup-wizard__content {
  height: inherit;
}
.signup-wizard__content :global(.ant-form-large .ant-form-item .ant-form-item-control-input) {
  min-height: auto;
}
.signup-wizard__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.signup-wizard__info-col {
  margin-bottom: 16px;
}
.signup-wizard__actions-col {
  align-self: flex-end;
  margin-bottom: 16px;
}
