.input-radio {
  &:global(.ant-radio-wrapper-disabled) {
    color: var(--gray-6);
  }
  &:global(.ant-radio-wrapper .ant-radio-inner) {
    width: 20px;
    height: 20px;
  }
  &:global(.ant-radio-wrapper .ant-radio-inner::after) {
    width: 28px;
    height: 28px;
    margin-block-start: -14px;
    margin-inline-start: -14px;
    background-color: var(--corporate-orange);
    border-radius: 50%;
  }
  &:global(.ant-radio-wrapper span.ant-radio + *) {
    padding-inline: 6px;
  }
  &:global(.ant-radio-wrapper .ant-radio-checked::after) {
    border-color: var(--corporate-orange);
  }
  &:global(.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after) {
    transform: scale(0.5);
  }
  &:global(.ant-radio-wrapper:hover :not(.ant-radio-disabled) .ant-radio-inner) {
    border-color: var(--corporate-orange);
  }
  &:global(.ant-radio-wrapper .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner) {
    background-color: var(--white);
    border-color: var(--corporate-orange);
  }
}
