.reset-password {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  min-height: 684px;
  padding: 80px 0 0;
  margin: 0;
  background: var(--main-gray-95) url("/assets/images/landing/landing-bg-top.svg") no-repeat;
  background-position: center top;
  background-size: cover;
  &.tablet {
    min-height: 576px;
  }
  &.mobile {
    min-height: 552px;
  }
}
.reset-password__holder {
  width: 100%;
  height: auto;
  padding: 32px;
  margin: 64px auto;
  background: var(--white);
  border-radius: 6px;
}
