.title {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  word-break: break-word;
}
.title-font-system {
  font-family: var(--font-family);
}
.title-font-base {
  font-family: var(--font-base);
}
.title-font-second {
  font-family: var(--font-second);
}
.title-font-weight-100 {
  font-weight: 100;
}
.title-font-weight-200 {
  font-weight: 200;
}
.title-font-weight-400 {
  font-weight: 400;
}
.title-font-weight-600 {
  font-weight: 600;
}
.title-font-weight-700 {
  font-weight: 700;
}
.title-font-weight-800 {
  font-weight: 800;
}
.title-alignment-horizontal-left {
  text-align: left;
}
.title-alignment-horizontal-center {
  text-align: center;
}
.title-alignment-horizontal-right {
  text-align: right;
}
.title-alignment-vertical-top,
.title-alignment-vertical-middle,
.title-alignment-vertical-bottom {
  display: inline-block;
}
.title-alignment-vertical-top {
  vertical-align: top;
}
.title-alignment-vertical-middle {
  vertical-align: middle;
}
.title-alignment-vertical-bottom {
  vertical-align: bottom;
}
.title-size-12 {
  font-size: 12px;
  line-height: 18px;
}
.title-size-14 {
  font-size: 14px;
  line-height: 20px;
}
.title-size-16 {
  font-size: 16px;
  line-height: 24px;
}
.title-size-18 {
  font-size: 18px;
  line-height: 24px;
}
.title-size-20 {
  font-size: 20px;
  line-height: 28px;
}
.title-size-22 {
  font-size: 22px;
  line-height: 28px;
}
.title-size-24 {
  font-size: 24px;
  line-height: 32px;
}
.title-size-30 {
  font-size: 30px;
  line-height: 36px;
}
.title-size-32 {
  font-size: 32px;
  line-height: 40px;
}
.title-size-36 {
  font-size: 36px;
  line-height: 40px;
}
.title-size-38 {
  font-size: 38px;
  line-height: 40px;
}
.title-size-44 {
  font-size: 44px;
  line-height: 50px;
}
.title-transform-capitalize {
  text-transform: capitalize;
}
.title-transform-uppercase {
  text-transform: uppercase;
}
.title-transform-lowercase {
  text-transform: lowercase;
}
.title-color-white-100 {
  color: var(--white);
}
.text-color-dark {
  color: var(--dark);
}
.title-color-orange-10 {
  color: var(--corporate-orange);
}
.title-color-gray-100 {
  color: var(--main-gray-1);
}
.title-color-gray-200 {
  color: var(--main-gray-2);
}
.title-color-gray-400 {
  color: var(--neutral-2);
}
.title-color-gray-500 {
  color: var(--main-gray-5);
}
.title-color-gray-700 {
  color: var(--main-gray-7);
}
.text-color-gray-600 {
  color: var(--main-gray-6);
}
.title-color-gray-900 {
  color: var(--main-gray-9);
}
.title-color-gray-950 {
  color: var(--main-gray-95);
}
.title-color-gray-1000 {
  color: var(--main-gray-10);
}
.title-color-blue-600 {
  color: var(--blue-6);
}
.title-color-blue-700 {
  color: var(--blue-7);
}
.title-color-gray-6 {
  color: var(--gray-6);
}
.title-color-dark {
  color: var(--dark);
}
.title-color-black {
  color: var(--black);
}
.title-color-inherit {
  color: inherit;
}
.title-skeleton:global.ant-skeleton {
  min-width: 50px;
  height: 40px;
}
.title-skeleton:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input) {
  width: 100%;
  min-width: inherit;
  height: inherit;
  line-height: inherit;
}
.title-skeleton-height-12:global.ant-skeleton {
  height: 20px;
}
.title-skeleton-height-14:global.ant-skeleton {
  height: 22px;
}
.title-skeleton-height-16:global.ant-skeleton {
  height: 24px;
}
.title-skeleton-height-18:global.ant-skeleton {
  height: 28px;
}
.title-skeleton-height-20:global.ant-skeleton {
  height: 28px;
}
.title-skeleton-height-24:global.ant-skeleton {
  height: 32px;
}
.title-skeleton-height-30:global.ant-skeleton {
  height: 38px;
}
.title-skeleton-height-36:global.ant-skeleton {
  height: 40px;
}
.title-skeleton-height-44:global.ant-skeleton {
  height: 50px;
}
.title-skeleton-width-10:global.ant-skeleton {
  min-width: 100px;
}
.title-skeleton-width-12:global.ant-skeleton {
  min-width: 120px;
}
.title-skeleton-width-14:global.ant-skeleton {
  min-width: 140px;
}
.title-skeleton-width-16:global.ant-skeleton {
  min-width: 160px;
}
.title-skeleton-width-18:global.ant-skeleton {
  min-width: 180px;
}
.title-skeleton-width-20:global.ant-skeleton {
  min-width: 200px;
}
.title-skeleton-width-40:global.ant-skeleton {
  min-width: 400px;
}
.title-skeleton-width-full:global.ant-skeleton {
  width: 100%;
  min-width: 100%;
}
.title-skeleton-width-full,
.title-box-full {
  width: 100%;
}
.title--mb-4 {
  margin-bottom: 8px;
}
.title--mb-8 {
  margin-bottom: 8px;
}
.title--mb-12 {
  margin-bottom: 12px;
}
.title--mb-16 {
  margin-bottom: 16px;
}
.title--mb-24 {
  margin-bottom: 24px;
}
.title--mb-32 {
  margin-bottom: 32px;
}
