.cta {
  width: 100%;
  padding: 48px 0;
}
.cta.tablet {
  padding-top: 40px;
  padding-bottom: 40px;
}
.cta.mobile {
  padding-top: 32px;
  padding-bottom: 32px;
}
.cta__header_mobile {
  margin-bottom: 16px;
}
.cta--base {
  background-color: var(--dark);
}
.cta--primary {
  background-color: var(--corporate-orange);
}
