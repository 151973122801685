.main {
  position: relative;
  width: 100%;
}
.main:global(.ant-layout-content) {
  min-height: auto;
  font-family: var(--font-family);
}
.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
}
.inner.private {
  align-items: initial;
  justify-content: initial;
}
.inner.no-gutters {
  padding: 0;
}
