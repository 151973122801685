.phone:global(.ant-space) {
  display: flex;
  align-items: flex-start;
}
.phone:global(.ant-space) > :not(:first-child) {
  flex: 1;
}
.compact:global(.ant-space) {
  display: flex;
  column-gap: 0;
}
.compact:global(.ant-space) > :not(:first-child) {
  flex: 1;
  margin-left: -1px;
}
