:global(.ant-spin-spinning) {
  color: inherit;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-spinning :global(.ant-spin-spinning) {
  animation: spin 1s infinite linear;
}
.loading-size {
  font-size: inherit;
}
.loading-size-extra-large :global(.ant-spin .ant-spin-dot) {
  font-size: 48px;
}
.loading-size-extra-large :global(.ant-spin .ant-spin-dot-item) {
  width: 24px;
  height: 24px;
}
.loading-size-large {
  font-size: inherit;
}
.loading-size-large :global(.ant-spin .ant-spin-dot) {
  font-size: 32px;
}
.loading-size-large :global(.ant-spin .ant-spin-dot-item) {
  width: 16px;
  height: 16px;
}
.loading-size-medium {
  font-size: inherit;
}
.loading-size-medium :global(.ant-spin .ant-spin-dot) {
  font-size: 24px;
}
.loading-size-medium :global(.ant-spin .ant-spin-dot-item) {
  width: 12px;
  height: 12px;
}
.loading-size-small {
  font-size: inherit;
}
.loading-size-small :global(.ant-spin .ant-spin-dot) {
  font-size: 18px;
}
.loading-size-tiny {
  font-size: inherit;
}
.loading-size-tiny :global(.ant-spin .ant-spin-dot) {
  font-size: 12px;
}
.loading-size-tine :global(.ant-spin .ant-spin-dot-item) {
  width: 6px;
  height: 6px;
}
.loading-color-blue-8 :global(.ant-spin .ant-spin-dot-item) {
  background-color: var(--primary-blue-8);
}
.loading-color-orange-8 :global(.ant-spin .ant-spin-dot-item) {
  color: var(--main-orange-8);
}
.loading-color-green-8 :global(.ant-spin .ant-spin-dot-item) {
  background-color: var(--states-success);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
