:global(.ant-modal-root .ant-modal-mask) {
  background-color: rgb(19 37 48 / 92%) !important;
}
.modal:global(.ant-modal .ant-modal-close-x) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.modal:global(.ant-modal .ant-modal-content) {
  background-color: var(--blue-1);
}
.modal:global(.ant-modal .survey-wrapper .ant-modal-content) {
  background-color: transparent;
  border-radius: 0;
}
.modal [class~="modal-wrap-dark-mask"] {
  z-index: 1035;
  background: var(--black);
}
.modal-gutter-none:global(.ant-modal .ant-modal-content) {
  padding: var(--modal-gutter-none);
}
.modal-gutter-small:global(.ant-modal .ant-modal-content) {
  padding: var(--modal-gutter-small);
}
.modal-gutter-middle:global(.ant-modal .ant-modal-content) {
  padding: var(--modal-gutter-middle);
}
.modal-gutter-large:global(.ant-modal .ant-modal-content) {
  padding: var(--modal-gutter-large);
}
.modal-width-auto:global(.ant-modal) {
  width: auto;
}
.close-btn-type-outside {
  &:global(.ant-modal .ant-modal-content) {
    border-radius: 4px;
  }
  &:global(.ant-modal .ant-modal-close) {
    top: -36px;
    right: 0;
    color: var(--main-gray-5);
    &:hover {
      color: var(--gray-1);
    }
  }
  :global .ant-modal-close-icon {
    font-size: 20px;
  }
}
.close-btn-type-inside {
  &:global(.ant-modal .ant-modal-content) {
    border-radius: 2px;
  }
  &:global(.ant-modal .ant-modal-close) {
    top: 0;
    right: 0;
    color: var(--neutral-75);
    &:hover {
      color: var(--neutral-75);
    }
  }
  :global .ant-modal-close-icon {
    font-size: 10px;
  }
}
:global(.ant-modal-root .ant-modal-wrap) {
  opacity: 0;
  animation: fade-in 0.6s ease-in-out 0.26s forwards;
}

/* animation */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
