.select {
  min-width: 120px;
  color: inherit;
  text-align: left;
}
.select.disabled {
  color: var(--gray-6);
}
.select.min-width {
  min-width: 76px;
}
.select .select [class*="__control"] {
  border-color: var(--gray-2);
  border-radius: 0;
}
.select [class*="__control"]:focus,
.select [class*="__control"]:hover {
  z-index: 1;
}
.select [class*="__input-container"] {
  padding: 0;
  margin: 0;
}
.select [class*="__menu"] {
  z-index: 20;
}
.select [class*="-group"] {
  margin-bottom: 5px;
}
.select [class*="__group"] [class*="-option"] {
  width: auto;
  margin-left: 20px;
}
.select.focused [class*="__control"] {
  border-color: var(--orange-8);
}
.select .prefix-control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 0 0 10px;
  font-size: 11px;
  font-weight: 600;
  color: var(--blue-6);
  background: var(--blue-1);
  border-radius: 50%;
}
.select .prefix-control.active {
  color: var(--white);
  background: var(--orange-8);
}
.select.large {
  font-size: 16px;
}
.select.default {
  font-size: 14px;
}
.select.ghost {
  display: inline-block;
  min-width: auto;
}
.select.ghost [class*="__control"] {
  background-color: transparent;
}
.select.ghost [class*="__dropdown-indicator"] {
  padding: 2px;
}
.select.ghost [class*="__indicator-separator"] {
  display: none;
}
.select.no-separator [class*="__indicator-separator"] {
  display: none;
}
.select.without-border [class*="__control"] {
  border: none;
  box-shadow: none;
}
.select.header [class*="__placeholder"] {
  color: white;
}
.select.header [class*="__single-value"] {
  color: white;
}
.select.header [class*="__indicator-separator"] {
  border-color: white;
}
.select.header [class*="__indicator"] {
  color: white;
}
.select.footer [class*="__control"] {
  background-color: var(--main-gray-9);
  border-color: var(--neutral-85);
}
.select.footer [class*="__placeholder"] {
  color: var(--main-gray-7);
}
.select.footer [class*="__single-value"] {
  color: var(--main-gray-7);
}
.select.footer [class*="__indicator-separator"] {
  background-color: var(--neutral-85);
}
.select.footer [class*="__indicator"] {
  color: var(--main-blue-gray-4);
}
:global .select--vpoz__menu-list {
  scrollbar-width: thin;
  scrollbar-color: white transparent;
}
:global .select--vpoz__menu-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 100%;
}
:global .select--vpoz__menu-list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100%;
}
:global .select--vpoz__menu-list::-webkit-scrollbar-thumb {
  background: var(--main-gray-2);
  border: 3px solid var(--main-gray-4);
  border-radius: 3px;
}
.select-item {
  display: flex;
  align-items: center;
}
.select-item span {
  margin-right: 8px;
}
