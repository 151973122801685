.search-file-upload {
  width: 100%;
}
.search-file-upload__dragger-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px;
}
.search-file-upload__dragger-content--mt10 {
  margin-top: 10px;
}
.search-file-upload__error {
  margin-top: 8px;
  margin-left: 24px;
}
