.header {
  position: relative;
  z-index: 100;
  background: var(--main-gray-9);
}
.header:global(.ant-layout-header) {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0;
}
.header.ghost {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}
.header__main {
  padding: 20px 0;
  line-height: 1;
}
.header.light {
  background: transparent;
  .header__main {
    padding: 40px 0;
  }
}
.header .header-top {
  display: grid;
  width: 100%;
  line-height: 1;
}
.header .header-top-main {
  width: 100%;
}
.header .header-top-user-menu {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
