.user-manager-banner {
  display: flex;
  align-items: flex-start;
  height: auto;
  min-height: 112px;
  padding: 16px;
  margin: 0 0 64px;
  background: var(--orange-2);
}
.user-manager-banner__icon {
  padding-right: 16px;
}
.user-manager-banner__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
