.proximity {
  width: 100%;
  height: auto;
  padding: 80px 0;
  background: var(--main-blue-gray-1);
}
.proximity__heading {
  display: flex;
  flex-direction: column;
  gap: var(--small-gap);
  width: 100%;
  height: 76px;
}
.proximity__content {
  width: 100%;
  height: auto;
  margin: 64px 0 0;
}
.proximity__content_list-item {
  display: flex;
  gap: var(--list-gap-normal);
  align-items: flex-start;
}
.proximity__content_list-item-icon {
  width: 90px;
  min-width: 90px;
  height: 90px;
  padding: 12px;
  background: var(--corporate-orange);
}
.proximity__content_list-items:global(.ant-list .ant-list-items) {
  display: flex;
  gap: var(--list-gap-normal);
  align-items: flex-start;
}
.proximity__content_list-items:global(.ant-list .ant-list-items .ant-list-item) {
  flex: 1 1;
  align-items: flex-start;
}
.proximity__content_list-item_list {
  width: 100%;
  height: auto;
}
.proximity__content_list-item_list-items {
  width: 100%;
  height: auto;
}
.proximity__content_list-item_list-items_list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-inline-start: 24px;
  list-style: disc;
}
.proximity__content_list-item_list-items_list li::marker {
  color: var(--gray-6);
}
