.notification {
  padding: 14px 24px;
}
.notification__icon {
  width: 80px;
  min-width: 80px;
  height: 80px;
}
.notification :global .ant-notification-notice-content {
  min-height: 80px;
}
.notification :global .ant-notification-notice-icon {
  margin: 0;
}
.notification:global(
    .ant-notification-notice
      .ant-notification-notice-content
      .ant-notification-notice-with-icon
      .ant-notification-notice-message
  ) {
  padding-left: 96px;
  margin: 0;
  margin-bottom: 8px;
}
.notification:global(
    .ant-notification-notice
      .ant-notification-notice-content
      .ant-notification-notice-with-icon
      .ant-notification-notice-description
  ) {
  padding-left: 96px;
  margin: 0;
}
.title {
  font-size: 20px;
  line-height: 28px;
}
.desc {
  color: var(--gray-8);
}
.success {
  color: var(--success-default);
}
.error {
  color: var(--states-error);
}
.warning {
  color: var(--states-warning);
}
.info {
  color: var(--main-gray-9);
}

@media (width >= 768px) and (width <= 1200px) {
  .notification {
    width: 348px;
  }
}

@media (width <= 767px) {
  .notification {
    width: 100%;
  }
}
