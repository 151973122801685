.link {
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  word-break: break-all;
  touch-action: manipulation;
  cursor: pointer;
  outline: 0;
  transition: color 0.3s;
}
.link-icon {
  display: flex;
  align-items: center;
}
.link-type-disabled {
  color: #00000040;
  word-break: break-word;
  cursor: not-allowed;
  user-select: none;
}
.link-type-default {
  color: var(--blue-6);
}
.link-type-default:hover {
  color: var(--main-blue-gray-3);
}
.link-type-default:focus {
  color: var(--blue-6);
}
.link-type-default:active {
  color: var(--blue-7);
}
.link-type-primary {
  color: var(--primary-blue-8);
}
.link-type-primary:hover,
.link-type-primary:focus {
  color: var(--primary-blue-7);
}
.link-type-primary:active {
  color: var(--primary-blue-9);
}
.link-type-secondary {
  color: var(--dark);
}
.link-type-secondary:hover,
.link-type-secondary:focus,
.link-type-secondary:active {
  color: var(--primary-blue-8);
}
.link-type-link {
  color: var(--white);
  background-color: var(--primary-blue-8);
}
.link-type-link:hover,
.link-type-link:focus {
  color: var(--white);
  background-color: var(--primary-blue-7);
}
.link-type-link:active {
  color: var(--white);
  background-color: var(--primary-blue-9);
}
.link-color-inherit {
  color: inherit;
}
.link-color-main-orange-10 {
  color: var(--corporate-orange);
}
.link-bg-color-main-orange-10:active,
.link-bg-color-main-orange-10:focus {
  background-color: var(--corporate-orange);
}
.link-color-white-100 {
  color: var(--white);
}
.link-color-white-100:hover {
  color: var(--white);
}
.link-color-white-100:active {
  color: var(--white);
}
.link-color-white-100:focus {
  color: var(--white);
}
.link-color-blue-800 {
  color: var(--primary-blue-8);
}
.link-color-blue-800:hover,
.link-color-blue-800:focus,
.link-color-blue-800:active {
  color: var(--primary-blue-5);
}
.link-color-gray-600,
.link-color-gray-600:focus {
  color: var(--main-gray-6);
}
.link-color-gray-600:hover,
.link-color-gray-600:active {
  color: var(--main-gray-2);
}
.link-border-color-blue-800 {
  border: 1px solid var(--primary-blue-8);
}
.link-bg-color-orange-8 {
  background-color: var(--main-orange-8);
}
.link-bg-color-orange-8:hover,
.link-bg-color-orange-8:active,
.link-bg-color-orange-8:focus {
  background-color: var(--corporate-orange);
}
.link-bg-color-orange-10 {
  background-color: var(--corporate-orange);
}
.link-bg-color-orange-10:hover,
.link-bg-color-orange-10:active,
.link-bg-color-orange-10:focus {
  background-color: var(--main-orange-8);
}
.link-bg-color-blue-800 {
  background-color: var(--primary-blue-8);
}
.link-bg-color-blue-800:hover,
.link-bg-color-blue-800:focus,
.link-bg-color-blue-800:active {
  background-color: var(--primary-blue-5);
}
.link-bg-color-gray-400 {
  background-color: var(--main-gray-4);
}
.link-box-inline {
  display: inline-flex;
}
.link-box-block {
  display: inline-flex;
  width: 100%;
}
.link-text-value {
  display: flex;
  align-items: center;
}
.link-no-styles {
  display: block;
  text-wrap: unset;
  word-break: unset;
  cursor: unset;
}
.link-no-styles:hover,
.link-no-styles:active,
.link-no-styles:visited {
  color: unset;
}
.link-no-styles .link-text-value {
  display: block;
  align-items: unset;
}
.link-disabled {
  pointer-events: none;
}
.link-alignment-vertical-top {
  vertical-align: top;
}
.link-alignment-vertical-middle {
  vertical-align: middle;
}
.link-alignment-vertical-bottom {
  vertical-align: bottom;
}
.link-alignment-horizontal-left {
  justify-content: flex-start;
}
.link-alignment-horizontal-center {
  justify-content: center;
}
.link-alignment-horizontal-right {
  justify-content: flex-end;
}
.link-padding-middle {
  padding: 6px 16px;
  border-radius: 2px;
}
.link-decoration-none {
  text-decoration: none;
}
.link-decoration-underline {
  text-decoration: underline;
}
.link-decoration-underline:hover {
  text-decoration: underline;
}
.link-size-12 {
  font-size: 12px;
  line-height: 16px;
}
.link-size-14 {
  font-size: 14px;
  line-height: 22px;
}
.link-size-16 {
  font-size: 16px;
  line-height: 24px;
}
.link-size-20 {
  font-size: 20px;
  line-height: 28px;
}
.link-size-24 {
  font-size: 24px;
  line-height: 32px;
}
.link-font-system {
  font-family: var(--font-family);
}
.link-font-base {
  font-family: var(--font-base);
}
.link-font-second {
  font-family: var(--font-second);
}
.link-font-weight-300 {
  font-weight: 300;
}
.link-font-weight-400 {
  font-weight: 400;
}
.link-font-weight-600 {
  font-weight: 600;
}
.link-font-weight-700 {
  font-weight: 700;
}
.link-text-icon {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
