.list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-gutter-bottom {
  margin-bottom: 8px;
}
.list-item {
  color: inherit;
}
.list-item-label-option {
  padding-left: 8px;
}
.list-row {
  display: flex;
  flex-wrap: wrap;
}
.list-row-item {
  flex-grow: 1;
  line-height: 1;
}
