.pro-wholesalers {
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
  min-height: 708px;
  padding: 0;
  margin: 0;
  background: var(--main-gray-95) url("/assets/images/landing/landing-bg-top.svg") no-repeat;
  background-position: center top;
  background-size: cover;
}
.pro-wholesalers.top {
  padding: 80px 0 0;
}
.pro-wholesalers__heading {
  margin: 154px 0 0;
}
.pro-wholesalers__benefits {
  margin: 24px 0 0;
}
.pro-wholesalers__benefits :global(.ant-list .ant-list-items) {
  padding: 0 28px;
  margin: 0;
  list-style-type: disc;
}
.pro-wholesalers__benefits :global(.ant-list .ant-list-item) {
  display: list-item;
}
.pro-wholesalers__benefits li.pro-wholesalers__benefits-list_item {
  height: 28px;
  padding: 0;
}
.pro-wholesalers__benefits li.pro-wholesalers__benefits-list_item::marker {
  color: var(--main-gray-3);
}
