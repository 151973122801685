.block {
  position: relative;
}
.block-alignment-horizontal-left {
  text-align: left;
}
.block-alignment-horizontal-center {
  text-align: center;
}
.block-alignment-horizontal-right {
  text-align: right;
}
