.password-validation-checks {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  justify-content: space-between;
  & :global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input) {
    width: 100%;
    min-width: auto;
    height: 20px;
    margin-bottom: 4px;
  }
  & .icon {
    color: var(--states-success);
  }
}
