.image,
.image-tooltip {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.image-tooltip {
  width: 200px;
  height: 200px;
}
.image img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
