.mass-search-table-cell-header-tooltip:global(.ant-tooltip) {
  width: 308px;
  max-width: 92%;
}
.mass-search-table-cell-header-tooltip:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 12px;
}
.mass-search-table-cell-header-tooltip-trigger {
  cursor: help;
}
