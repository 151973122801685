.greeting {
  width: 100%;
  height: auto;
  min-height: 112px;
  margin: 0 0 64px;
  background: var(--primary-blue-1);
}
.greeting-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding: 16px;
}
.user-avatar {
  padding-right: 16px;
}
.user-welcome {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
