.layout-app-footer {
  width: 100%;
  height: auto;
}
.layout-app-footer:global(.ant-layout-footer) {
  padding: 64px 0 16px;
  background: var(--main-gray-95);
}
.footer__date {
  padding: 16px 0 0;
  text-align: center;
}
.footer__cookie-btn {
  margin-top: 24px;
}
