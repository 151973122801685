.container {
  width: 100%;
  min-width: var(--container-min-width);
  max-width: var(--container-width);
  height: 100%;
  padding: 0 var(--container-padding);
  margin: 0 auto;
}
.container--tablet {
  padding: 0 var(--container-padding-mobile);
}
.container--mobile {
  padding: 0 var(--container-padding-mobile);
}
.container--full {
  max-width: none;
  padding: 0;
  margin: 0;
}
.container--max {
  max-width: var(--container-max-width);
  padding: 0;
}
