.freedom {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.freedom :global(.ant-col) {
  display: grid;
  align-items: center;
  text-align: center;
}
.freedom__content-item {
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2%;
  text-wrap: balance;
}
.freedom__content-item--ration-11 {
  aspect-ratio: 1 / 1;
}
.freedom__content-item--ration-21 {
  aspect-ratio: 2 / 1;
}
.freedom__content-item--bg-orange-gradient {
  background: repeating-linear-gradient(-45deg, #f57e2e, #f57e2e 14%, #f85a00 14%, #f85a00 28%);
}
.freedom__content-item--bg-dark {
  background: var(--dark);
}
.freedom__content-item--bg-main-gray-9 {
  background: var(--main-gray-9);
}
.freedom__content-item--bg-white {
  background: var(--white);
}
.freedom__content-item--bg-main-gray-85 {
  background: var(--main-gray-85);
}
.freedom__content-item--bg-main-orange-5 {
  background: var(--main-orange-5);
}
.freedom__content-item--bg-gray-7 {
  background: var(--gray-7);
}
.freedom__content-item--bg-gray-8 {
  background: var(--gray-8);
}
.freedom__content-item_img-container {
  display: grid;
  align-items: center;
  padding: 12%;
}
