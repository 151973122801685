.logo {
  display: inline-block;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 40px;
  vertical-align: middle;
}
.logo.tablet {
  max-width: 176px;
  max-height: 36px;
}
.logo.mobile {
  max-width: 80px;
  max-height: 24px;
}
.box {
  display: inline-block;
  font-size: 0;
  line-height: 1;
  vertical-align: top;
}
