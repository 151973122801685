.simplicity {
  display: flex;
  gap: 0;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  .simplicity_content {
    padding: min(80px, 8vh) 0 min(80px, 8vh) calc((100% - var(--container-width)) / 2);
    margin: auto;
    .simplicity_content__heading {
      display: flex;
      flex-direction: column;
      gap: var(--small-gap);
      width: 100%;
      height: auto;
    }
    .simplicity_items {
      width: 100%;
      height: auto;
      margin: 64px 0 0;
    }
    .simplicity_items-list:global(.ant-list .ant-list-items) {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }
    .simplicity_items-list:global(.ant-list .ant-list-items .ant-list-item) {
      gap: var(--list-gap-normal);
      justify-content: flex-start;
    }
    .simplicity_items-list__item-icon {
      width: 90px;
      min-width: 90px;
      height: 90px;
      padding: 12px;
      background: var(--corporate-orange);
    }
    .simplicity_items-list__item-text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .simplicity_items-list__item-text-title {
      display: flex;
      gap: var(--list-gap-normal);
    }
    .simplicity_items-list__item-text-alert {
      align-self: center;
    }
  }
  .simplicity_media {
    display: flex;
    .simplicity_media__img {
      position: relative;
      width: 100%;
      height: auto;
      aspect-ratio: 712 / 1024;
      margin: auto;
    }
  }
}
