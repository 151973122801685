.wrapper-price {
  display: inline;
}
.price {
  font-size: inherit;
}
.postfix {
  padding-left: 6px;
}
.skeleton:global.ant-skeleton.ant-skeleton-input {
  width: 100%;
  min-width: auto;
}
.unavailable {
  color: var(--gray-6);
}
.disabled {
  color: var(--main-gray-4);
}
