.input {
  color: inherit;
  border-color: var(--main-gray-3);
  border-radius: 2px;
  transition:
    height 0s,
    padding 0s;
}
.input:global.ant-input-affix-wrapper-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 22px;
}
.input:global.ant-input-affix-wrapper-lg input {
  font-size: var(--font-size);
}
.input :global .ant-input-lg {
  line-height: 22px;
}
.input.disabled {
  color: var(--gray-6);
}
.input::placeholder {
  color: var(--main-gray-7);
}
