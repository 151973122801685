.signin-form-info {
  padding-top: 8px;
}
.signin-form-info-text {
  padding-top: 8px;
  margin: 0;
}
.signin-form-info-text:first-child {
  padding-top: 0;
}
