.basket-checkout-credit-cards__safety-marks {
  padding: 24px 80px;
  background: var(--main-gray-2);
}
.basket-checkout-credit-cards__safety-marks--item {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  background: var(--white);
}
