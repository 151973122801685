.form-skeleton__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 462px;
}
.form-skeleton__loading :global(.ant-skeleton.ant-skeleton-element) {
  display: flex;
}
.form-skeleton__skeleton-row {
  width: 100%;
  height: auto;
  margin: 32px 0 0;
}
.form-skeleton__skeleton-header:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input) {
  width: 68%;
  height: 22px;
}
.form-skeleton__skeleton-input:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input) {
  width: 98%;
  height: 32px;
  margin: 8px 0 0;
}
