.markdown {
  & p {
    margin-top: 0;
  }
  & h1 {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  & h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  & h3 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  & ul {
    padding-left: 20px;
    & ul {
      list-style-type: none;
      & > li {
        text-indent: -5px;
      }
      & > li::before {
        text-indent: -5px;
        content: " - ";
      }
    }
  }
  & p + ul,
  & p + ol {
    margin-top: -10px;
  }
}
.static-page-content {
  width: 100%;
  height: auto;
  font-family: var(--font-base);
  font-size: 14px;
  line-height: 22px;
  & .row {
    margin-bottom: 40px;
  }
  & .title {
    margin-bottom: 24px;
  }
}
.table {
  & th {
    width: 200px;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    background-color: var(--blue-1);
    border-right: 1px solid var(--white);
  }
  & th:last-child {
    width: 476px;
  }
  & td {
    padding: 4px 12px;
    box-shadow: 0 -1px 0 0 var(--main-blue-gray-3) inset;
  }
}
