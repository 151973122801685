.input:global(.ant-input) {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 22px;
  color: inherit;
  border-color: var(--main-gray-3);
  border-radius: 2px;
  transition:
    height 0s,
    padding 0s;
}
.input:global(.ant-input-affix-wrapper) {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 2px;
}
.input:global(.ant-input.ant-input-sm) {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.375;
}
.input:global(.ant-input.ant-input-lg) {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 22px;
}
.input:global(.ant-input:hover),
.input:global(.ant-input:focus),
.input:global(.ant-input .ant-input-focused) {
  border-color: var(--primary-blue-6);
}
.input:global(.ant-input:focus),
.input:global(.ant-input .ant-input-focused) {
  box-shadow: 0 0 0 2px rgb(0 104 215 / 20%);
}
.input:global(.ant-input-group-wrapper.input-sbwm) {
  padding-top: 0;
  padding-bottom: 0;
}
.input:global(.ant-input-affix-wrapper .ant-input.ant-input-sm) {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.375;
}
.input:global(.ant-input-affix-wrapper .ant-input.ant-input-lg) {
  line-height: 22px;
}
.input:global(.ant-input-affix-wrapper .ant-input:hover),
.input:global(.ant-input-affix-wrapper .ant-input:focus),
.input:global(.ant-input-affix-wrapper .ant-input .ant-input-focused) {
  border-color: var(--primary-blue-6);
}
.input:global(.ant-input-affix-wrapper .ant-input:focus),
.input:global(.ant-input-affix-wrapper .ant-input .ant-input-focused) {
  box-shadow: 0 0 0 2px rgb(0 104 215 / 20%);
}
.input.disabled {
  color: var(--gray-6);
}
.input.centered {
  text-align: center;
}
.input.number::-webkit-outer-spin-button,
.input.number::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}
.input.number[type="number"] {
  appearance: textfield;
}
.input::placeholder {
  color: var(--main-gray-7);
}
