.variety {
  width: 100%;
  height: auto;
  padding: 80px 0;
  background: var(--main-gray-95);
}
.variety__heading {
  display: flex;
  flex-direction: column;
  gap: var(--small-gap);
  width: 100%;
  height: 92px;
}
.variety__content {
  width: 100%;
  height: auto;
  margin: 64px 0;
}
.variety__content_list:global(.ant-list .ant-list-items) {
  display: flex;
  gap: var(--list-gap-normal);
}
.variety__content_list-item {
  display: flex;
  gap: var(--list-gap-normal);
  align-items: center;
  text-wrap: balance;
}
.variety__content_list-item-icon {
  width: 90px;
  min-width: 90px;
  height: 90px;
  background: var(--corporate-orange);
}
.variety__content_brands {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  width: 100%;
  height: auto;
}
.variety__content_brands-item {
  position: relative;
  display: block;
  width: 158px;
  height: 80px;
  padding: 0;
  margin: 0;
}
