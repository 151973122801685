.input:global(.ant-input-number) {
  width: 100%;
  font-size: var(--font-size);
  color: inherit;
  border-color: var(--main-gray-3);
  border-radius: 2px;
  transition:
    height 0s,
    padding 0s;
}
.input:global(.ant-input-lg) {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 22px;
}
.input.disabled {
  color: var(--gray-6);
}
.input.centered {
  text-align: center;
}
.input :global(.ant-input-number-input::placeholder) {
  color: var(--main-gray-7);
}
