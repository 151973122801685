.basket-empty {
  height: inherit;
}
.basket-empty__badge:global(.ant-badge .ant-badge-count) {
  width: 24px;
  height: 24px;
  margin-top: 6px;
  font-size: 14px;
  line-height: 24px;
  background: var(--corporate-orange);
  border-radius: 12px;
}
.basket-empty__icon {
  &:global(.anticon) {
    font-size: 50px;
    color: var(--neutral-75);
  }
}
