.pro-trader {
  width: 100%;
  height: auto;
  padding: 120px 0;
  background: var(--main-blue-gray-1);
}
.pro-trader__list {
  position: relative;
  width: 100%;
  height: auto;
  margin: 64px 0 0;
}
.pro-trader__list:global(.ant-list .ant-list-items) {
  display: flex;
  flex-wrap: wrap;
  gap: var(--list-gap-normal);
}
.pro-trader__list:global(.ant-list .ant-list-items .ant-list-item) {
  align-items: flex-start;
  justify-content: flex-start;
  border-block-end: none;
}
.pro-trader__list-item {
  display: flex;
  flex: 1 1 calc(50% - 8px);
  gap: var(--list-gap-normal);
  align-items: flex-start;
  margin: 0 0 48px;
}
.pro-trader__list-item-icon {
  width: 90px;
  min-width: 90px;
  height: 90px;
}
.pro-trader__list-notice {
  position: absolute;
  bottom: 0;
  left: 0;
}
.pro-trader__register {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 168px;
  background: var(--main-gray-95);
}
.pro-trader__register-catchphrase {
  width: 100%;
  height: fit-content;
  margin: 0 16px;
  text-align: center;
  text-wrap: balance;
}
.pro-trader__register-button {
  width: 100%;
}
