.basket-delivery-address-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.basket-delivery-address-form__radio-group {
  &:global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.basket-delivery-address-form__radio-sub-group {
  gap: 24px;
  padding-left: 48px;
}
.basket-delivery-address-form_billing-address {
  opacity: 0;
  animation: fade-in 0.8s;
  animation-fill-mode: forwards;
}

/* animation */
@keyframes fade-in {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
}
