.tag {
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
  color: inherit;
}
.tag-type-primary {
  display: initial;
  padding: 0 8px;
  color: var(--main-gray-1);
  background-color: var(--main-orange-8);
  border-color: var(--main-orange-8);
  border-radius: 4px;
}
.tag-type-alert:global(.ant-tag) {
  display: inline-block;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--dark);
  background: var(--cta-1);
  border-color: var(--cta-12);
  border-radius: 2px;
}
.tag-color-gray-7 {
  color: var(--main-gray-7);
}
