.products-table-item-details {
  position: relative;
  width: 726px;
  height: var(--detail-popover-height);
  padding: var(--detail-popover-padding);
  background: var(--white);
  border-radius: 2px;
  box-shadow: 0 0 1px var(--main-gray-9);
}
.products-table-item-details_media {
  width: 100%;
  height: auto;
}
.products-table-item-details_list {
  height: auto;
  max-height: calc(var(--detail-popover-height) - var(--detail-popover-padding) * 2);
  overflow: auto;
}
.products-table-item-details_header {
  margin: 0 0 12px;
}
.products-table-item-details_items {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 10px;
}
.products-table-item-details_items::-webkit-scrollbar {
  width: 6px;
  appearance: none;
}
.products-table-item-details_items::-webkit-scrollbar-thumb {
  background-color: var(--gray-45);
  border-radius: 4px;
  box-shadow: 0 0 1px var(--white);
}
.products-table-item-details_close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.products-table-item-details_close:global(.anticon) {
  color: var(--dark);
}
.article-number-cell-tooltip_icon {
  cursor: pointer;
}
.article-number-cell-tooltip:global(.ant-tooltip) {
  max-width: fit-content;
}
.article-number-cell-tooltip :global(.tooltip-gutter-small-sjEo.ant-tooltip .ant-tooltip-inner) {
  max-width: fit-content;
}
.skeleton-details_header:global(.ant-skeleton.ant-skeleton-element) {
  display: block;
  width: 86%;
  margin: 0 0 6px;
  span {
    width: 100%;
  }
}
.skeleton-details_subheader:global(.ant-skeleton.ant-skeleton-element) {
  display: block;
  width: 75%;
  span {
    width: 100%;
  }
}
.skeleton-details_items:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input-sm) {
  width: 100%;
  span {
    width: 100%;
  }
}
.skeleton-products-table-item-details_media:global(.ant-skeleton.ant-skeleton-element) {
  display: flex;
  justify-content: center;
  width: 100%;
}
