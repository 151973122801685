.radio-group {
  width: 100%;
  color: inherit;
  &:global(.ant-radio-inner) {
    border-color: var(--blue-5);
  }
  &:global(.ant-radio-inner::after) {
    background-color: var(--main-orange-8);
  }
}
