.about {
  width: 100%;
  height: auto;
}
.about.mobile {
  padding: 32px 0;
}
.about__content {
  padding-left: calc((100% - var(--container-width)) / 2);
}
.about__content_title {
  margin-bottom: 16px;
}
.about__content_title-mobile {
  margin-bottom: 8px;
  text-align: center;
}
.about__content_business {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 120px 0;
}
.about__content_business.tablet {
  padding: 48px 0;
}
.about__content_business.mobile {
  padding-top: 0;
  padding-bottom: 24px;
}
.about__content_details {
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  justify-content: space-between;
}
.about__media {
  height: 100%;
}
.about__media_images {
  height: inherit;
}
.slide-img {
  position: relative;
  width: 100%;
  min-height: 582px;
  aspect-ratio: 273 / 194;
  vertical-align: middle;
}
.slide-img.tablet {
  min-height: 412px;
}
.slide-img.mobile {
  min-height: 270px;
}
.slider-btns {
  display: inline-block;
  vertical-align: top;
}
.slider-btns button:nth-last-child(-n + 1) {
  margin-left: 16px;
}
.slider-btns-mobile {
  margin-top: 16px;
  text-align: center;
}
