.form-item {
  position: relative;
  line-height: 1;
}
.form-item:global(.ant-form-item .ant-form-item-control-input) {
  min-height: auto;
}
.form-item:global(.ant-form-item .ant-form-item-explain) {
  font-family: var(--font-base);
  font-size: var(--font-size-sm);
}
.form-item:global(.ant-form-item .ant-form-item-explain-error) {
  font-family: var(--font-base);
  font-size: var(--font-size-sm);
  color: var(--states-error);
}
.form-item:global(.ant-form-item .ant-form-item-label) {
  padding: 0;
  line-height: 1;
}
.form-item:global(.ant-form-item .ant-input::placeholder) {
  color: var(--gray-7);
}
.form-item:global(.ant-form-item .ant-form-item-label) > label {
  width: 100%;
  height: auto;
}
:global .ant-form-large.ant-form-vertical .ant-form-item .ant-form-item-label > label {
  height: auto;
}
.form-item:global(
    .ant-form-item-has-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input
  ) {
  border-color: var(--states-error);
}
.form-item--base {
  color: inherit;
}
.form-item--no-gutter:global(.ant-form-item) {
  margin: 0;
}
.form-item--no-last:global(.ant-form-item) {
  margin-bottom: 24px;
}
.form-item--small:global(.ant-form-item) {
  margin-bottom: 8px;
}
.form-item--middle:global(.ant-form-item) {
  margin-bottom: 16px;
}
.form-item--search-bar:global(.ant-form-item) {
  margin: 0;
}
.form-item--search-bar:global(.ant-input-suffix) {
  font-size: 12px;
  line-height: 14px;
  color: var(--neutral-6);
}
.form-item--search-bar:global(.ant-input-affix-wrapper-lg) {
  padding: 8px 11px;
}
.form-item--search-bar:global(.ant-select) {
  cursor: default;
}
.form-item--search-bar:global(input.ant-input) {
  font-size: 14px;
  color: var(--blue-9);
}
.form-item--header {
  margin: 0;
}
.form-item--header:global(.ant-form-item-label span) {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--blue-7);
  text-transform: uppercase;
}
.form-item--footer:global(.ant-form-item) {
  margin: 0;
}
.form-item--footer:global(.ant-form-item-label) {
  margin-bottom: 8px;
}
.form-item--footer:global(.ant-form-item-label span) {
  color: var(--blue-6);
}
.skeleton--group {
  padding-bottom: 24px;
}
.skeleton--group:global(.ant-skeleton.ant-skeleton-input) {
  width: 100%;
}
.skeleton--group:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input-lg) {
  width: 100%;
}
.skeleton:not(:last-child) {
  margin-bottom: 14px;
}
.skeleton:global(.ant-skeleton.ant-skeleton-element) {
  width: 100%;
  border-radius: 4px;
}
.skeleton:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input) {
  width: 100%;
  min-width: auto;
}
.skeleton-counter:global(.ant-skeleton.ant-skeleton-input) {
  width: 90px;
  min-width: 90px;
}
