.carousel-container .carousel-next-button,
.product-gallery-enlarged-carousel-content .carousel-next-button,
.carousel-container .carousel-previous-button,
.product-gallery-enlarged-carousel-content .carousel-previous-button {
  height: auto;
  font-size: 24px;
  color: var(--main-blue-gray-9);
}
.thumb-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 128px;
}
.product-gallery {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  max-height: calc(var(--detail-popover-height) - var(--detail-popover-padding) * 2);
}
.product-gallery_slide {
  > img {
    height: 200px;
    object-fit: contain;
  }
}
.product-gallery_photo-container {
  position: relative;
  width: 100%;
  height: auto;
}
.product-gallery_photo-container__brand {
  position: absolute;
  inset: 12px 0 0 12px;
  z-index: 1000;
  width: 20px;
  height: 20px;
}
.product-gallery_photo {
  position: static;
}
.product-gallery_thumb-image-container {
  position: relative;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.product-gallery_thumb-image {
  width: 40px;
  height: 40px;
}
.product-gallery .carousel-container {
  display: grid;
  max-width: 100%;
  margin: 0 auto;
}
.product-gallery .carousel-container.carousel-container-arrow {
  grid-template-columns: auto calc(100% - 80px) auto;
}
.carousel-container .carousel-previous-button > span,
.carousel-container .carousel-next-button > span {
  display: flex;
}
