.autodoc-pro-slogan {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 0;
  background: var(--main-gray-95);
  .autodoc-pro-slogan__image {
    position: relative;
    width: 380px;
    height: 64px;
  }
}
