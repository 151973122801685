.upload :global(.ant-upload-list-item-error),
.upload :global(.ant-upload-list-item-done) {
  padding: 4px;
}
.upload :global(.ant-upload-list-item-done) {
  border: 0;
}
.upload :global(.ant-upload.ant-upload-select-picture-card) {
  width: 112px;
  height: 112px;
  border-radius: 4px;
}
.upload :global(.ant-upload-list-picture-card-container) {
  width: 112px;
  height: 112px;
}
.upload .upload-button-icon :global(.anticon svg) {
  fill: var(--main-gray-5);
}
.upload .upload-button-text {
  display: flex;
  padding-top: 8px;
}
.preview {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}
.preview-title {
  margin-bottom: 16px;
}
.preview-button {
  margin-top: 8px;
}
