.buying-steps {
  --buying-steps-bg: var(--blue-1);

  width: 100%;
  height: auto;
  padding: 120px 0 112px;
  background: var(--buying-steps-bg);
}
.buying-steps__header {
  margin: 0 0 64px;
}
.buying-steps__content-list:global(.ant-list .ant-list-items) {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.buying-steps__content-list:global(.ant-list .ant-list-items .ant-list-item) {
  align-items: center;
  justify-content: flex-start;
  padding: 0 14px;
}
.buying-steps__content-list_item {
  flex: 0 0 33.3333%;
  flex-direction: column;
  gap: var(--small-gap);
  align-content: center;
  margin: 0 0 64px;
}
.buying-steps__content-list_item.steps-progress {
  --dot-bg: var(--corporate-orange);
  --dot-color: var(--white);
  --dot-size: 2px;
  --dot-space: 4px;
}
.buying-steps__content-list_item.steps-progress.none {
  background:
    linear-gradient(90deg, white calc(var(--dot-space) - var(--dot-size)), transparent 1%) 0 60px /
      var(--dot-space) var(--dot-space) repeat-x,
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) 0 60px /
      var(--dot-space) var(--dot-space) repeat-x;
}
.buying-steps__content-list_item.steps-progress.left {
  background:
    linear-gradient(90deg, var(--blue-1) 50%, transparent 50%),
    linear-gradient(90deg, white calc(var(--dot-space) - var(--dot-size)), transparent 1%) 0 60px /
      var(--dot-space) var(--dot-space) repeat-x,
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) 0 60px /
      var(--dot-space) var(--dot-space) repeat-x;
}
.buying-steps__content-list_item.steps-progress.right {
  background:
    linear-gradient(90deg, transparent 50%, var(--blue-1) 50%),
    linear-gradient(90deg, white calc(var(--dot-space) - var(--dot-size)), transparent 1%) 0 60px /
      var(--dot-space) var(--dot-space) repeat-x,
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) 0 60px /
      var(--dot-space) var(--dot-space) repeat-x;
}
.buying-steps__content-list_item-media {
  position: relative;
  width: 60%;
  height: 140px;
  margin: 0 auto;
  background: var(--buying-steps-bg);
}
.buying-steps__content-list_item-content {
  text-align: center;
}
.buying-steps__content-list_notice {
  position: absolute;
  bottom: 10px;
  left: 0;
}
