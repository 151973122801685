.divider.divider--gutter-empty {
  margin: 0;
}
.divider.divider--gutter-small {
  margin: 8px 0;
}
.divider.divider--gutter-middle {
  margin: 16px 0;
}
.divider.divider--gutter-large {
  margin: 24px 0;
}
.divider.divider--position-bottom {
  margin-top: 0;
}
.divider.divider--color-gray-3 {
  border-color: var(--main-blue-gray-3);
}
.divider.divider--color-gray-35 {
  border-color: var(--main-gray-35);
}
